<template>
    <div>
        <el-tabs v-model="activeName" @tab-click="handleClick" style="padding-left: 10px">
            <el-tab-pane label="图表" name="line">
                <!-- <el-button size="small" type="primary" class="btnSearch" @click="download()">下载数据</el-button>  -->

                <div class="btnSearch">
                    <el-select size="small" class="btnSearch" v-model="queryForm.equipNo" @change="changeDevice()" filterable clearable placeholder="请选择设备">
                        <el-option v-for="item in queryEquipList" :key="item.id" :label="item.equipNo" :value="item.equipNo">
                        </el-option>
                    </el-select>
                </div>
                <div class="btnSearch">
                    <el-date-picker v-model="startTime" align='right' type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-value="new Date()"
                                    @change="changeDate()">
                    </el-date-picker>
                </div>

            </el-tab-pane>

            <!-- 表格全展示 -->

            <el-tab-pane label="数据" name="data">
                <!-- <el-button size="small" type="primary" class="btnSearch" @click="download()">下载数据</el-button> -->

                <div class="btnSearch">
                    <el-select size="small" class="btnSearch" v-model="queryForm.equipNo" @change="changeDevice()" filterable clearable placeholder="请选择设备">
                        <el-option v-for="item in queryEquipList" :key="item.id" :label="item.equipNo" :value="item.equipNo">
                        </el-option>
                    </el-select>
                </div>
                <div class="btnSearch">
                    <el-date-picker v-model="startTime" align='right' type="date" placeholder="选择日期" format="yyyy-MM-dd" value-format="yyyy-MM-dd HH:mm:ss" :default-value="new Date()"
                                    @change="changeDate()">
                    </el-date-picker>
                </div>

                <!-- <el-table :data="tableData" style="width: 100%"> -->
                <el-table :data="tableData" tooltip-effect="dark" style="width: 100%" :height="tableHeight">
                    <el-table-column prop="time" label="时间" width="720">
                    </el-table-column>
                    <el-table-column prop="totaltimes" label="次数" >
                    </el-table-column>
                </el-table>

            </el-tab-pane>
        </el-tabs>

        <div id="line" ref="line" v-show="showChart" style="width: 100%; height: 500px;margin-top:30px"></div>
    </div>
</template>


<script>
import { fDateTimeFormat } from '@/utils/utilsset'
import {sanyouAxios} from "@/api";

export default {
    data() {
        return {
            currentUserId: '',
            activeName: 'line',

            //选择设备
            queryEquipList: [],
            queryForm: {
                equipNo: 'en02',
            },
            startTime: '',
            pickerOptions: {
                disabledDate(time) {
                    return time.getTime() > Date.now()
                },
            },
            tableHeight: 500,
            //折线图
            myChart:'',
            showChart:true,
            option: {
                title: {
                    text: '24小时数据',
                    // subtext: 'Demo 虚构数据',
                    x: 'center',
                },
                //标题
                legend: {
                    orient: 'horizontal',
                    x: 'left',
                    y: 'top',
                    // data: ['猜想', '预期', '实际'],
                },

                grid: {
                    left: '3%',
                    right: '4%',
                    bottom: '6%',
                    containLabel: true,
                },
                xAxis: {
                    name: '时刻',
                    type: 'category',
                    data: [],
                },
                yAxis: {
                    name: '次数',
                    type: 'value',
                    // min: 0, // 配置 Y 轴刻度最小值
                    // max: 50, // 配置 Y 轴刻度最大值
                    // splitNumber: 5, // 配置 Y 轴数值间隔
                    scale: true,
                },

                series: [
                    // 多组折线图数据
                    {
                        name: '检测次数',
                        data: [0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0, 0],
                        type: 'line',
                    },
                ],
                color: ['#4CAE85'],
                tooltip: {
                    show: true,
                    trigger: 'axis',
                },
            },
            tableData: [],
        }
    },
    created() {
    },
    mounted() {
        let date = new Date()
        let year = date.getFullYear()
        let month = date.getMonth()
        let day = date.getDate()
        this.currentUserId = localStorage.getItem('id')
        // 1. 基于准备好的dom，初始化echarts实例
        //console.log(this.$refs.line);
        this.myChart = this.$echarts.init(this.$refs.line);
        this.startTime = fDateTimeFormat(new Date(year, month, day));

        this.loadEquipList()
        this.loadData()
    },
    methods: {
        //点击标签事件
        handleClick(tab, event) {
            if (tab.name === 'line') {
                this.drawLine()
            } else {
                this.showData()
            }
        },
        drawLine() {
            this.showChart=true;
            // 2. 发起请求，获取数据
            //   loadData();
            //   sanyouAxios({
            //     headers: {
            //       userId: this.currentUserId,
            //     },
            //     method: 'POST',
            //     url: '/copper/copperTimeData',
            //     data: {
            //       deviceNo: this.queryForm.equipNo,
            //       userId: this.currentUserId,
            //       startTime: this.startTime,
            //     },
            //   }).then((res) => {
            //     if (res.data.status == 200) {
            //       let data = res.data.data
            //       this.option.xAxis.data = []
            //       this.option.series[0].data = []
            //       // 3. 使用刚指定的配置项和数据，显示图表
            //       // t
            //       this.tableData = []
            //       for (let x in data) {
            //         let temp = { time: x, totaltimes: data[x] }
            //         // temp[x] = data[x]
            //         this.tableData.push(temp)
            //       }
            //       for (let i = 0; i < 24; i++) {
            //         this.option.xAxis.data.push(i + '时')
            //         this.option.series[0].data.push(data[i])
            //       }
            //       myChart.setOption(this.option)
            //       console.log(this.tableData)
            //     } else {
            //       this.$message({
            //         duration: 2000,
            //         message: res.data.msg,
            //         type: 'warning',
            //       })
            //     }
            //   })
            this.myChart.setOption(this.option)
        },
        loadData() {
            sanyouAxios({
                headers: {
                    userId: this.currentUserId,
                },
                method: 'POST',
                url: '/copper/copperTimeData',
                data: {
                    deviceNo: this.queryForm.equipNo,
                    userId: this.currentUserId,
                    startTime: this.startTime,
                },
            }).then((res) => {
                if (res.data.status === 200) {
                    let data = res.data.data
                    this.option.xAxis.data = []
                    this.option.series[0].data = []
                    // 3. 使用刚指定的配置项和数据，显示图表
                    this.tableData = []
                    for (let x in data) {
                        let temp = { time: x, totaltimes: data[x] }
                        // temp[x] = data[x]
                        this.tableData.push(temp)
                    }
                    for (let i = 0; i < 24; i++) {
                        this.option.xAxis.data.push(i + '时')
                        this.option.series[0].data.push(data[i])
                    }
                    this.drawLine();
                } else {
                    this.$message({
                        duration: 2000,
                        message: res.data.msg,
                        type: 'warning',
                    })
                }
            });
        },

        //获取用户所拥有权限的设备信息
        loadEquipList() {
            sanyouAxios({
                method: 'GET',
                url:
                    '/equipment/getUserEquip?userId=' +
                    this.currentUserId +
                    '&&equipType=1'
            }).then((res) => {
                    if (res.data.status === 200) {
                        this.queryEquipList = res.data.data;
                        this.queryForm.equipNo = this.queryEquipList[0].equipNo;
                    } else {
                        this.$message({
                            type: 'warning',
                            duration: 2000,
                            message: '获取设备信息失败!',
                        })
                    }
                })
        },

        changeDevice() {
            this.loadData();
        },
        changeDate() {
            this.loadData()
            if (!this.startTime) {
                this.drawLine()
            }
        },
        showData() {
            this.showChart=false;
            // this.isShow = true;
            // this.loadData(1, 20)
        },
    },
}
</script>
<style>
.btnSearch {
    margin: 5px 10px;
    float: right;
}
</style>
